import React from "react"

import Layout from "../../components/layout/layout-stp"
import Seo from "../../components/seo"

import * as style from '../../components/content/content.module.scss'

import { graphql } from "gatsby"

import AKSMenu from "./components/aks_sidemenu"
import Img from "gatsby-image"


const AveKodeStudios = ({ data }) => (
  <Layout>
    <Seo title="Ave Kode Studios" bodyClass="stp"/>
    
    <div className="column column--sidebar alignright">
      <div className="sticky">
       <AKSMenu/>
      </div>
    </div>
  <div className="column column--content">
  <Img className={style.imageBodyHalf + ' float-right'} fluid={data.AveKode.childImageSharp.fluid}/>


<h1>Independent Music Director &amp; Artist Consultant</h1><br/>

<p><strong>Musicianship Training</strong><br/>
Executive Producer: Album Projects <br/>
Audition &amp; Live Performance Prep <br/>
Bookings &amp; Scheduling <br/>
Career Advancement &amp; Longevity </p>

<p>Female operated, works with various artists from established to up and coming, helping them put together and release albums, audition prep, to facilitating their live shows and overall musical direction. </p>

<p>She can assist you with all aspects of your career to help maximize the longevity of your artist brand, at affordable rates that work within your budget. Let us take care of the back end while you focus on your craft. </p>

<p>Educated and certified industry pro with over 25 years in the business as a musician (soloist, accompanist, studio, in bands: stage &amp; touring etc), vocalist songwriter composer and executive producer. </p>

<p>Songs she’s written and produced have rotated and charted on College, Commercial, Internet Radio &amp; North American DJ Pools. </p>

<hr/>
<p><strong>Credits include:</strong><br/> Universal Music, YTV, Much Music </p>

<hr/>
<h2>Let’s discuss your next project! </h2>


<p>I’m the Indie M.D. industry pro in the K.N.O.W! [Knowledge, Notability, Originality, Wow factor!]</p>


  </div>
  </Layout>
)

export default AveKodeStudios

export const query = graphql`
  query {
    AveKode: file(relativePath: {eq: "logo-ave_kode_studios.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 830) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
